var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"3","md":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Uygulama Tipi")))]),_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.uygulamaTuruOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.duyuruData.sUygulamaTuru),callback:function ($$v) {_vm.$set(_vm.duyuruData, "sUygulamaTuru", $$v)},expression:"duyuruData.sUygulamaTuru"}})],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"3","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Başlangıç Tarih'),"label-for":"dtBaslangicTarih"}},[_c('b-form-input',{attrs:{"type":"date"},model:{value:(_vm.duyuruData.dtDuyuruBaslangic),callback:function ($$v) {_vm.$set(_vm.duyuruData, "dtDuyuruBaslangic", $$v)},expression:"duyuruData.dtDuyuruBaslangic"}})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"3","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Bitiş Tarih'),"label-for":"dtBitisTarih"}},[_c('b-form-input',{attrs:{"type":"date"},model:{value:(_vm.duyuruData.dtDuyuruBitis),callback:function ($$v) {_vm.$set(_vm.duyuruData, "dtDuyuruBitis", $$v)},expression:"duyuruData.dtDuyuruBitis"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('label',{staticClass:"section-label mb-1"},[_vm._v(_vm._s(_vm.$t("Başlık")))]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.duyuruData.sBaslik),callback:function ($$v) {_vm.$set(_vm.duyuruData, "sBaslik", $$v)},expression:"duyuruData.sBaslik"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('label',{staticClass:"section-label mb-1"},[_vm._v(_vm._s(_vm.$t("Duyuru")))]),_c('editor',{attrs:{"api-key":"'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'","init":{
            plugins: [
              'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage textcolor colorpicker' ],
            toolbar:
              'undo redo | bold italic underline strikethrough | \
              fontselect fontsizeselect formatselect | \
              alignleft aligncenter alignright alignjustify | outdent indent | \
              numlist bullist checklist | \
              forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | \
              fullscreen  preview  print | insertfile image media pageembed link anchor | \
              a11ycheck ltr rtl | showcomments addcomment | uploadimage',
            language: 'tr_TR',
            min_height: 300,
            valid_elements: '*[*]',
            extended_valid_elements: 'style,link[href|rel]',
            custom_elements: 'style,link,~link',
            force_br_newlines: true,
            forced_root_block: false,
          }},model:{value:(_vm.duyuruData.sDuyuru),callback:function ($$v) {_vm.$set(_vm.duyuruData, "sDuyuru", $$v)},expression:"duyuruData.sDuyuru"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('save-button',{attrs:{"onClickSave":_vm.saveDuyuru.bind(false),"showSaveAndClose":false}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }