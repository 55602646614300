<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="3" md="4" class="mb-md-0 mb-2">
          <label>{{ $t("Uygulama Tipi") }}</label>
          <v-select
            v-model="duyuruData.sUygulamaTuru"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="uygulamaTuruOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-col>
        <b-col cols="3" md="4" class="mb-md-0 mb-2">
          <b-form-group :label="$t('Başlangıç Tarih')" label-for="dtBaslangicTarih">
            <b-form-input
              type="date"
              v-model="duyuruData.dtDuyuruBaslangic"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" md="4" class="mb-md-0 mb-2">
          <b-form-group :label="$t('Bitiş Tarih')" label-for="dtBitisTarih">
            <b-form-input
              type="date"
              v-model="duyuruData.dtDuyuruBitis"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <label class="section-label mb-1">{{ $t("Başlık") }}</label>
          <b-form-input v-model="duyuruData.sBaslik" type="text" />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" md="12" lg="12">
          <label class="section-label mb-1">{{ $t("Duyuru") }}</label>
          <editor
            v-model="duyuruData.sDuyuru"
            api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
            :init="{
              plugins: [
                'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage textcolor colorpicker',
              ],
              toolbar:
                'undo redo | bold italic underline strikethrough | \
                fontselect fontsizeselect formatselect | \
                alignleft aligncenter alignright alignjustify | outdent indent | \
                numlist bullist checklist | \
                forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | \
                fullscreen  preview  print | insertfile image media pageembed link anchor | \
                a11ycheck ltr rtl | showcomments addcomment | uploadimage',
              language: 'tr_TR',
              min_height: 300,
              valid_elements: '*[*]',
              extended_valid_elements: 'style,link[href|rel]',
              custom_elements: 'style,link,~link',
              force_br_newlines: true,
              forced_root_block: false,
            }"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <save-button
            :onClickSave="saveDuyuru.bind(false)"
            :showSaveAndClose="false"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
// Save Button
import SaveButton from '@/components/SaveButton.vue';

// Alert
import AlertService from '@/common/alert.service';

// Options
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';

import { getCurrentDate, apiToDate } from '@core/utils/filter'

export default {
  components: {
    SaveButton,
    Editor,
  },

  data() {
    return {
      duyuruData: {
        id: 0,
        sBaslik: null,
        sDuyuru: null,
        sUygulamaTuru: null,
        dtDuyuruBaslangic: getCurrentDate(),
        dtDuyuruBitis: getCurrentDate({ type: 'd', value: 1 }),
      },
      uygulamaTuruOptions: StaticOptions.uygulamaTuruOptions,
    };
  },

  methods: {
    fetchDuyuru() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== -1) {
        this.$store
          .dispatch('fetchDuyuru', { id: this.$router.currentRoute.params.id })
          .then(response => {
            if (response.statusCode === 200) {
              response.result.dtDuyuruBaslangic = apiToDate(response.result.dtDuyuruBaslangic)
              response.result.dtDuyuruBitis = apiToDate(response.result.dtDuyuruBitis)
              this.duyuruData = response.result;
            } else {
              AlertService.error(this, response.message);
            }
          });
      }
    },

    saveDuyuru() {
      if (this.duyuruData.sBaslik === null || this.duyuruData.sDuyuru === null 
          || this.duyuruData.sUygulamaTuru === null || this.duyuruData.dtDuyuruBaslangic === null
          || this.duyuruData.dtDuyuruBitis === null) {
        AlertService.warning(this, 'Lütfen alanları doldurunuz.')
        return;
      }
      this.$store.dispatch('saveDuyuru', this.duyuruData).then(response => {
        if (response.statusCode === 200) {
          this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: response.message },
          });
          this.$router.push({ name: 'manage-duyuru-save', params: { id: response.result.sGuid } });
          this.fetchDuyuru();
        } else {
          AlertService.error(this, response.message);
        }
      });
    },
    getCurrentDate,
  },

  created() {
    this.fetchDuyuru();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
